<template>
  <div class="invite-member">
    <div class="add-member" v-if="showAdd">
      <div class="input-item">
        <div class="title">绑定邮箱</div>
        <input v-model="email" placeholder="请输入任天堂账号所绑定的邮箱" />
      </div>
      <div class="add-confirm-btn" @click="addConfirm">确定</div>
    </div>
    <div class="invite-desc" v-else>
      <div class="driver-info">
        <img :src="require('../../../assets/avatar1.png')" />
        <div class="membership-info">
          <div class="info-text">司机：{{ car.driver }}</div>
          <div class="info-text">剩余时长：{{ car.remainDays }}天</div>
        </div>
      </div>
      <div class="invite-note">
        <div class="note-text">
          1、由于任天堂限制，一次只能发一个邀请，建议按紧急顺序添加乘客
        </div>
        <div class="note-text">
          2、由于任天堂限制，乘客一旦上车，将不可再下车，请谨慎选择队友
        </div>
        <div class="note-text">
          3、座位状态为「邮件待接受」时，如需要换邮箱请联系客 服更换
        </div>
      </div>
    </div>
    <div class="member-info" v-if="!showAdd">
      <div class="member-list">
        <div class="member-box" v-for="(member, idx) in car.members">
          <img :src="require('../../../assets/avatar' + (idx % 5) + '.png')" />
          <div class="member-status">
            <div class="status">
              {{
                member.status == 3
                  ? '待上车'
                  : member.status == 6
                  ? '邮件待接受'
                  : member.status == 4
                  ? '已上车'
                  : '邮件已过期'
              }}
            </div>
            <div class="email">{{ member.email }}</div>
          </div>
          <div class="op-btn" @click="memberOp(member)">
            {{
              member.status == 3
                ? '修改邮箱'
                : member.status == 6
                ? '重发邮件'
                : '重发邮件'
            }}
          </div>
        </div>
        <div
          class="add-member-btn"
          @click="addMember"
          v-if="car.members.length < 6"
        >
          <span
            style="
              font-size: 0.5rem;
              margin-right: 0.04rem;
              margin-bottom: 0.01rem;
            "
            >+</span
          >添加乘客
        </div>
      </div>
    </div>
    <Loading :display="showLoading"></Loading>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
  </div>
</template>
<script>
import {
  getCharteredCarInfo,
  addChartedCarPassenger,
  updateChartedCarPassenger
} from '@/api/carpool/index'
import { reportError } from '@/utils/log'
import Loading from '@/components/Loading'
import Toast from '@/components/Toast'

export default {
  name: 'InviteMember',
  data () {
    return {
      showLoading: false,
      showToast: false,
      toastMsg: '',
      car: {
        carId: 0,
        remainDays: 1,
        driver: '',
        members: []
      },
      email: '',
      showAdd: false,
      op: 'reSendEmail',
      member: {
        orderId: '',
        status: 3,
        email: ''
      }
    }
  },
  components: {
    Loading,
    Toast
  },
  methods: {
    async memberOp (member) {
      this.member = member
      this.op =
        member.status == 3
          ? 'updateEmail'
          : member.status == 6
          ? 'reSendEmail'
          : member.status == 4
          ? 'getOff'
          : 'reSendEmail'

      if (this.op == 'updateEmail') {
        this.showAdd = true
      } else {
        this.updateChartedCarPassenger()
      }
    },
    addMember () {
      this.op = 'addMember'
      this.showAdd = true
    },
    async updateChartedCarPassenger () {
      this.showLoading = true
      let res = await updateChartedCarPassenger({
        email: this.email,
        op: this.op,
        orderId: this.member.orderId,
        carId: this.car.carId
      })
      this.showLoading = false
      if (res.data.code == 0) {
        this.showToast = true
        this.showAdd = false
        this.email = ''
        this.getCharteredCarInfo()
      } else if (res.data.code == 2) {
        this.toastMsg = res.data.message
        this.showToast = true
      } else {
        this.toastMsg = '操作失败，请联系客服'
        this.showToast = true
      }
    },
    async addChartedCarPassenger () {
      this.showLoading = true
      let res = await addChartedCarPassenger({
        carId: this.car.carId,
        email: this.email
      })
      this.showLoading = false
      if (res.data.code == 0) {
        this.showAdd = false
        this.car.members.push({
          email: this.email,
          status: res.data.status,
          orderId: res.data.orderId
        })
        this.email = ''
      } else {
        if (res.data.code == 2) {
          this.toastMsg = res.data.message
          this.showToast = true
        } else {
          this.toastMsg = '添加成员失败，请联系客服'
          this.showToast = true
        }
      }
    },
    checkMail (email) {
      return /^[0-9a-zA-Z_.+-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
        email
      )
    },
    addConfirm () {
      if (this.checkMail(this.email)) {
        if (this.op == 'addMember') {
          this.addChartedCarPassenger()
        } else {
          this.updateChartedCarPassenger()
        }
      } else {
        this.toastMsg = '请输入正确的邮箱'
        this.showToast = true
      }
    },
    async getCharteredCarInfo () {
      try {
        this.car.members = []
        let res = await getCharteredCarInfo({
          carId: this.car.carId,
          uuid: localStorage.getItem('uuid')
        })
        if (res.data.code == 0) {
          for (let member of res.data.members) {
            if (member.uuid == localStorage.getItem('uuid')) {
              let time = new Date(member.familyExpiredAt) - new Date()
              this.car.remainDays =
                parseInt(time / (60 * 60 * 24 * 1000)) >= 0
                  ? parseInt(time / (60 * 60 * 24 * 1000))
                  : 0
              this.car.driver = member.email
            } else {
              this.car.members.push(member)
            }
          }
        }
        console.log(this.car.members)
      } catch (error) {
        reportError(error)
      }
    }
  },
  async created () {
    try {
      if (this.$route.query.carId) {
        this.car.carId = this.$route.query.carId
        this.getCharteredCarInfo()
      } else {
        this.$router.push('/home')
      }
    } catch (error) {
      reportError(error)
    }
  }
}
</script>

<style lang="less" scoped>
.invite-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  position: relative;
  .invite-desc {
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, #1dd098 0%, #06b7b5 100%);
    .driver-info {
      height: 1.6rem;
      margin-left: 0.24rem;
      display: flex;
      align-items: center;
      img {
        width: 1.16rem;
        height: 1.16rem;
      }
      .membership-info {
        margin-left: 0.24rem;
        display: flex;
        height: 1.16rem;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 0.28rem;
        color: white;
      }
    }
    .invite-note {
      font-size: 0.28rem;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .note-text {
        width: 7.16rem;
        margin-bottom: 0.06rem;
        &:last-child {
          margin-bottom: 0.8rem;
        }
      }
    }
  }
  .member-info {
    top: 3.5rem;
    position: absolute;
    width: 7.5rem;
    border-radius: 0.32rem 0.32rem 0.06rem 0.06rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fbfcfc;
    .member-list {
      width: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .member-box {
        img {
          width: 1.1rem;
          width: 1.1rem;
        }
        &:first-child {
          margin-top: 0.36rem;
        }
        &:last-child {
          margin-bottom: 0.36rem;
        }
        margin-top: 0.18rem;
        width: 6.48rem;
        height: 2rem;
        background: #ffffff;
        box-shadow: 0px 0px 0.12rem 0.1rem rgba(21, 21, 21, 0.05);
        border-radius: 0.12rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .member-status {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 1.1rem;
          width: 2.78rem;
          .status {
            font-size: 0.32rem;
            font-weight: bold;
            color: #5f5f5f;
          }
          .email {
            font-size: 0.24rem;
            color: #5f5f5f;
            display: flex;
            align-items: center;
            overflow-y: hidden;
            overflow-x: auto; //超出的文本隐藏
            text-overflow: ellipsis; //用省略号显示
            &::-webkit-scrollbar {
              width: 0 !important;
            }
            white-space: nowrap; //不换行
          }
        }
        .op-btn {
          color: #5f5f5f;
          font-size: 0.28rem;
          width: 1.52rem;
          height: 0.64rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ecefee;
          border-radius: 0.04rem;
          cursor: pointer;
        }
      }
      .add-member-btn {
        cursor: pointer;
        margin-top: 0.36rem;
        margin-bottom: 0.36rem;
        width: 6.48rem;
        height: 2rem;
        background: #ffffff;
        box-shadow: 0px 0px 0.12rem 0.1rem rgba(21, 21, 21, 0.05);
        border-radius: 0.12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        font-weight: 500;
        color: #6a6a6a;
      }
    }
  }
  .add-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    .input-item {
      height: 0.9rem;
      width: 7.02rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 0.28rem;
        &::after {
          content: '*';
          color: red;
        }
      }
      input {
        width: 65%;
        border: none;
        height: 0.4rem;
        padding: 0.24rem 0;
        font-size: 0.28rem;
        outline: none;
        text-align: right;
        &::placeholder {
          font-size: 0.28rem;
          color: #c5c6c5;
        }
        &:focus {
          border: none;
        }
        &:active {
          border: none;
        }
        &:-moz-placeholder {
          text-align: right;
        }
        &::-moz-placeholder {
          text-align: right;
        }
        &::-webkit-input-placeholder {
          text-align: right;
        }
        &:-ms-input-placeholder {
          text-align: right;
        }
      }
      border-bottom: 0.02rem solid #f6f7f8;
    }
    .add-confirm-btn {
      cursor: pointer;
      margin-top: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.02rem;
      height: 0.8rem;
      background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
      border-radius: 0.4rem;
      color: white;
      font-size: 0.32rem;
    }
  }
}
</style>
